@import '~antd/dist/antd.css';

.App {
  min-height: 100%;
}

main {
  position: relative;
  min-height: calc(100vh - 112px) !important;
}

.ant-scrolling-effect {
  width: 100% !important;
}

.auth-layout {
  height: calc(100vh - 64px) !important;
}


#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}


.site-layout .site-layout-background {
  background: #fff;
}


.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  padding-left: 8px;
}


/* Calendar */
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  height: 50px;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.login {
  height: 100%;
  background-image: linear-gradient(180deg, rgba(30, 30, 30, 0.8), rgba(105, 105, 105, 0.2)), url('./assets/login-city.jpg');
  background-size: cover;
}
@media only screen and (max-width: 576px) {
  .login {
    background-size: cover;
    background-image: linear-gradient(180deg, rgba(30, 30, 30, 1), rgba(105, 105, 105, 0.5)), url('./assets/login-city.jpg');
  }
}
@media only screen and (max-width: 576px) {
  .login h2 {
    color: white;
  }
  .login .text {
    color: white;
  }
  #login {
    color: white;
  }
  .login .social-sign-up {
    background-color:  rgba(255, 255, 255, 1);
    border-radius: 40px;
    padding: 5px;
    padding-top: 1px;
  }
}


.login-card {
  background:white;
  border-radius: 30px;
  /* border: none */
}

.login-text-field {
  border-radius: 20px;
}

#signup > .ant-form-item {
  margin-bottom: 16px;
}

.ant-card-meta-title {
  white-space: pre-line;
}


.hideOnMobile {
  display: none;
}
@media only screen and (min-width: 768px) {
  .hideOnMobile {
    display: block;
  }
}

.hideOnDesktop {
  display: block;
}
@media only screen and (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }
}

.dashboard-row {
  padding-top: 10px;
  padding-bottom: 10px;
}

.welcome-back-widget {
  background-size: cover;
  background-image: linear-gradient(100deg, rgba(30, 30, 30, 0.9), rgba(20, 20, 20, 0.2)), url('./assets/welcome-back.jpg');
}

/* carousel css */

.ant-carousel .slick-dots li button {
  background: #636363;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #636363;
}

@media only screen and (max-width: 768px) {
  .ant-carousel .slick-dots {
    bottom: -20px;
  }
}

.scrollable-list {
  width: 100%;
}
.scrollable-list .ant-list-item {
  cursor: pointer;
}
.scrollable-list .ant-list-item:hover {
  border-radius: 8px;
  background-color:  rgba(30, 30, 30, 0.08);
  transition: 0.2s;
}